import { IonIcon } from "@ionic/react";
import { Button } from "components/common/Button";
import Asset from "models/Asset";
import FormRecord from "models/FormRecord";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import { getSyncIconDetails } from "utils/sync/getSyncIconDetails";
import { SyncStatus } from "utils/sync/isSynced";
import { Note } from "components/common/Note/Note";
import Project from "models/Project";
import { useParams } from "react-router-dom";

type DeliveryStatusButtonListProps = {
	asset?: Asset;
	assetSyncState?: SyncStatus;
	records: FormRecord[];
	hasModelBim?: boolean;
	setOpenConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
	setOpenConfirmBIMModelModal: React.Dispatch<React.SetStateAction<boolean>>;
	setSyncOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DeliveryStatusButtonList: React.FC<DeliveryStatusButtonListProps> = (
	props: DeliveryStatusButtonListProps,
) => {
	const { projectRef } = useParams<{ projectRef: string }>();
	const { records, asset, assetSyncState, hasModelBim, setOpenConfirmModal, setOpenConfirmBIMModelModal, setSyncOpen } =
		props;
	const { t, i18n } = useTranslation();
	const isOnline = useAppSelector((state) => state.connection.isOnline);
	const [canDeliver, setCanDeliver] = React.useState(false);
	const formDeliveryStatus = asset?.status?.status;
	const isPendingValidation = formDeliveryStatus === "pendingValidation";
	const isValidated = formDeliveryStatus === "validated";
	const isRejected = formDeliveryStatus === "rejected";
	const isDiscarded = formDeliveryStatus === "discarded";

	const allRecordsSynced: boolean =
		records.map((record) => record.date_synced && record.date_synced >= record.date_updated).filter(Boolean).length ===
		records.length;

	const mustSync = assetSyncState !== "synced";

	useEffect(() => {
		Project.hasFeature(projectRef, "can-deliver").then((result) => {
			setCanDeliver(result && [null, "inProgress", "rejected"].includes(formDeliveryStatus ?? null));
		});
	}, [projectRef, formDeliveryStatus]);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				marginInline: "1rem",
			}}
		>
			{(isRejected || isValidated) && (
				<div>
					<div style={{ color: "var(--ion-color-gray500)", paddingBottom: "0.75rem" }}>
						{i18n.format(t("delivery_comment_label"), "capitalize")}
					</div>
					<div
						style={{
							border: "solid 1px var(--ion-color-gray200)",
							padding: "0.5rem 1rem",
							marginBottom: "1rem",
							borderRadius: "0.375rem",
							backgroundColor: "white",
							height: "9.75rem",
							overflowY: "auto",
						}}
					>
						{asset?.status?.comment}
					</div>
				</div>
			)}
			{isRejected && <Note variant="red">{i18n.format(t("delivery_rejected"), "capitalize")}</Note>}
			{isDiscarded && <Note variant="red">{i18n.format(t("delivery_discarded"), "capitalize")}</Note>}
			{isPendingValidation && <Note variant="yellow">{i18n.format(t("delivery_pending"), "capitalize")}</Note>}
			{isValidated && <Note variant="green">{i18n.format(t("delivery_valid"), "capitalize")}</Note>}
			{asset &&
				(hasModelBim ? (
					<Note variant="green">{i18n.format(t("delivered_bim_model"), "capitalize")}</Note>
				) : (
					<Button
						disabled={!asset.status || !isOnline || hasModelBim}
						variant="filled"
						onClickFunction={async () => {
							setOpenConfirmBIMModelModal(true);
						}}
					>
						{i18n.format(t("deliver_bim_model"), "capitalize")}
					</Button>
				))}
			{asset && !isPendingValidation && !isValidated && (
				<Button
					variant="filled"
					disabled={!mustSync || !isOnline || !asset.status}
					onClickFunction={() => setSyncOpen(true)}
				>
					{assetSyncState !== "synced" && (
						<IonIcon slot="start" size="small" src={getSyncIconDetails(assetSyncState || "synced", isOnline).icon} />
					)}
					{i18n.format(t("sync"), "capitalize")}
				</Button>
			)}
			{asset && canDeliver && (
				<Button
					disabled={!asset.status || !isOnline || mustSync || !allRecordsSynced}
					variant="filled"
					onClickFunction={async () => {
						setOpenConfirmModal(true);
					}}
				>
					{i18n.format(t("delivery_button"), "capitalize")}
				</Button>
			)}
		</div>
	);
};
